@font-face {
  font-family: 'SF Compact Display Thin';
  font-style: normal;
  font-weight: normal;
  src: local('SF Compact Display Thin'), url('../fonts/sf-compact-display-thin-58646eb43a785.woff') format('woff');
}

@font-face {
  font-family: 'SF Compact Display Medium';
  font-style: normal;
  font-weight: normal;
  src: local('SF Compact Display Medium'), url('../fonts/sf-compact-display-medium-5864711817c30.woff') format('woff');
}

@font-face {
  font-family: 'SF Compact Display Regular';
  font-style: normal;
  font-weight: normal;
  src: local('SF Compact Display Regular'), url('../fonts/SF-Compact-Display-Regular.woff') format('woff');
}

@font-face {
  font-family: 'SF Compact Display Semibold';
  font-style: normal;
  font-weight: normal;
  src: local('SF Compact Display Semibold'), url('../fonts/SF-Compact-Display-Semibold.woff') format('woff');
}
