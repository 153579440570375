.paginator {
  .pageIndicator {
    color: #333333;
    font-size: 14px;
    font-weight: 400;
    margin: 0 15px;
  }

  button {
    width: 27px;
    height: 27px;
    line-height: 1;
    vertical-align: unset;
    border-radius: 4px;
    background-color: #2f80f6;
    margin: 0 4px;
    padding: 0;
  }
}
