.page-content {
  @include media-breakpoint-up(md) {
    @include box-shadow(inset 50px 0px 10px -16px rgba(0, 0, 0, 0.07));
    padding: 0 120px 125px;
  }

  .page-content-header {
    height: 135px;
    display: flex;
    align-items: center;
    justify-content: center;

    .page-title {
      color: #333333;
      font-size: 40px;
      font-weight: bold;
      margin-left: 30px;
    }
  }
}
