.modal-body {
  margin-bottom: 60px;

  .payment-type-header {
    justify-content: center;
    color: #333333;
    font-size: 14px;
    font-weight: 400;
    padding: 33px 0;
  }

  .payment-type-selectors {
    .payment-type-selector {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      height: 161px;
      margin-bottom: 40px;

      &:first-child {
        border-right: 1px solid #efefef;
      }

      .card-logos {
        img {
          margin: 0 5px;
        }
      }

      .title {
        color: #333333;
        font-size: 20px;
        font-weight: 700;
      }

      button {
        width: 225px;
        height: 40px;
        border-radius: 8px;
        background-color: #2f80f6;
        color: #ffffff;
        font-size: 17px;
        font-weight: 400;
      }
    }
  }

  .new-betalingservice-account {
    padding: 0 2rem;

    .actions-panel {
      display: flex;
      justify-content: space-between;
      padding: 0 5px;

      button {
        width: 45%;
        height: 40px;
        border-radius: 8px;
        color: #ffffff;
        font-size: 17px;
        font-weight: 400;
      }
    }
  }
}
