// Styles only used by the public part of the application
.theme-public {
  @import "variables";

  // Components
  @import "navbar";

  // Pages
  @import "pages/login/index";
  @import "pages/generatePassword/index";

  // Styles
  font-family: "SF Compact Display Regular";
  background-color: #f4f4f4;
}
