section.consumption-panel {
  select {
    border-radius: 4px;
    border: 1px solid #8e8e8e;
    background-color: #ffffff;
    color: #333333;
    font-size: 17px;
    font-weight: 400;
    line-height: 30px;
  }

  .dashboard-section-body {
    padding: 21px 27px 30px;

    .consumptionChartWrapper {
      width: 100%;
      border-radius: 8px;
      background-color: #e8f1fe;
      padding: 30px;

      .chart-legend {
        display: flex;
        flex-direction: row;
        margin-bottom: 45px;

        .consumption-label {
          margin-right: 10px;
          padding: 5px 9px;
          border-radius: 4px;
          color: #ffffff;
          font-size: 11px;
          font-weight: 400;

          &.consumption-label-real {
            background-color: #e85f21;
          }
          &.consumption-label-estimated {
            background-color: #2f80f6;
          }
        }
      }

      canvas {
        height: 300px;
      }
    }
  }
}
