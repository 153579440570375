section {
  header {
    color: #333333;
    font-size: 20px;
    font-weight: 700;
  }

  &.agreement-subscription-section {
    background-color: #f5f5f5;
    padding: 27px 30px 1px;

    table {
      th {
        color: #333333;
        font-size: 14px;
        font-weight: 400;

        &:first-child {
          padding-left: 0;
        }
      }

      td {
        padding-top: 0;
        color: #333333;
        font-size: 17px;
        font-weight: 400;

        &:first-child {
          padding-left: 0;
        }

        .btn-link {
          padding: 0;
          font-size: 17px;
          font-weight: 400;

          img {
            position: relative;
            top: -7px;
            left: 4px;
          }
        }
      }
    }
  }

  &.agreement-deliveries-section, &.agreement-invoices-section {
    header {
      margin: 32px 30px 0;
    }

    @import "dataTable";

    td.delivery-type {
      img {
        width: 23px;
        height: 23px;
      }
    }
  }

  &.agreement-invoices-section {
    display: flex;
    flex-direction: column;

    table {
      td {
        .pdfInvoiceIcon {
          margin-left: 9px;
        }
      }
    }

    .paginator {
      margin: 36px 0;
      align-self: center;
    }
  }
}
