.private-modal-dialog {
  // General modal styles
  .modal-header {
    .modal-title {
      color: #333333;
      font-size: 24px;
      font-weight: 500;
      align-self: center;
    }

    img {
      width: 65px;
      height: 65px;
      margin-right: 13px;
    }
  }

  // Specific modal styles
  @import "../modals/addPaymentMethodModal";
}
