table {
  td {
    &.money {
      display: flex;
      justify-content: space-between;

      .amount {
        text-align: right;
      }
    }
  }
}
