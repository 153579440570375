// Styles only used by the private part of the application
.theme-private {
  @import "variables";

  // Layout
  @import "sideNav";

  // Components
  @import "navbar";
  @import "common/paginator";
  @import "common/contactSection";
  @import "common/tableBadges";
  @import "agreements/accordion";

  // Pages
  @import "pages/common";
  @import "pages/dashboard/index";
  @import "pages/agreements/index";
  @import "pages/consumption/index";
  @import "pages/profile/index";

  // Styles
  font-family: "SF Compact Display Regular";
  height: 100%;
  padding: 0;
}

@import "common/modal";
