.navbar {
  height: 94px;
  box-shadow: 0 0 18px rgba(25, 25, 22, 0.09);
  background-color: #ffffff;
  padding-left: 35px;
  padding-right: 44px;

  .navbar-item-userinfo {
    display: flex;
    align-items: center;
    height: 100%;

    img {
      margin-right: 16px;
    }

    .userinfo-name {
      color: #333333;
      font-size: 19px;
      font-weight: 400;
      height: 25px;
    }

    .userinfo-customerno {
      color: #868686;
      font-size: 14px;
      font-weight: 400;
    }
  }

  .navbar-item-logout {
    color: #2f80f6;
    font-size: 17px;
    font-weight: 400;
    line-height: 30px;

    .btn {
      display: flex;
      align-items: center;

      span {
        padding-left: 10px;
      }
    }
  }
}
