.agreements-accordion {
  .agreement-item {
    .agreement-header{
      display: flex;
      justify-content: space-between;

      .agreement-number {
      }
      .agreement-alias {
      }
    }
  }
}
